import React from "react";
import PadelRacket from "../assets/images/padel_racket.svg";

export default function ComingSoon() {
  return (
    <div style={{ marginTop: `50px`, marginBottom: `50px` }}>
      <div className="container">
        <div style={{ textAlign: "center", marginTop: `40px` }}>
          <div>
            <img src={PadelRacket} height="200" alt="Padel racket" />
            <h2 style={{ marginTop: `30px`, fontSize: `40px` }}>COMING SOON</h2>
            <p style={{ fontSize: `20px` }}>
              Her skjer det spennende ting!
              <br />
              Følg med på våre sosiale kanaler
              <br />
              og hold deg oppdatert.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
